import './polyfills/polyfills.js'

import { resized } from '@area17/a17-helpers'
import fontLoadObserver from '@area17/a17-helpers/src/utility/fontLoadObserver'
import lazyload from '@area17/a17-lazyload'

import * as Behaviors from './behaviors'
import { focusTrap } from './functions/focusTrap'
import { intersections } from './functions/intersections'
import { lockBody } from './functions/lockBody'
import { manageBehaviors } from './functions/manageBehaviors'
import { searchClear } from './functions/searchClear'
import { userIsTabbing } from './functions/userIsTabbing'

/*

  A17

  Doc: // Doc: https://code.area17.com/a17/fe-boilerplate/wikis/js-app

*/

const iosFix = () => {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const A17 = window.A17 || {}
const windowWidth = window.innerWidth

A17.breakpoints = ['xsmall', 'small', 'medium', 'large', 'xlarge', 'xxlarge', 'xxxlarge']

// window.A17.onReady = () => {
//   createBehavior.setBreakpointConfig({
//     xsmall: { start: null, end: 543 },
//     small: { start: 544, end: 767 },
//     medium: { start: 768, end: 1023 },
//     large: { start: 1024, end: 1279 },
//     xlarge: { start: 1280, end: 1439 },
//     xxlarge: { start: 1440, end: 1599 },
//     xxxlarge: { start: 1600, end: null },
//   })
// }

// HTML4 browser?
if (!A17.browserSpec || A17.browserSpec === 'html4') {
  // lets kill further JS execution of A17 js here
  throw new Error('HTML4')
}

document.addEventListener('DOMContentLoaded', function () {
  iosFix()
  // go go go
  manageBehaviors(Behaviors)
  // on resize, check
  resized()

  // look for intersections
  intersections()
  // listen for focus trapping requests
  focusTrap()

  searchClear()

  // watch for fonts loading
  fontLoadObserver({
    name: 'lelo',
    variants: [
      {
        name: 'Lelo',
        weight: '400'
      },
      {
        name: 'Lelo',
        weight: '400',
        style: 'italic'
      },
      {
        name: 'Lelo',
        weight: '500'
      },
      {
        name: 'Lelo',
        weight: '500',
        style: 'italic'
      }
    ]
  })
  fontLoadObserver({
    name: 'rosart',
    variants: [
      {
        name: 'Rosart',
        weight: '400'
      },
      {
        name: 'Rosart',
        weight: '400',
        style: 'italic'
      }
    ]
  })
  fontLoadObserver({
    name: 'canela',
    variants: [
      {
        name: 'Canela',
        weight: '300'
      },
      {
        name: 'Canela',
        weight: '300',
        style: 'italic'
      }
    ]
  })

  // listen for body lock requests
  lockBody()

  // lazy load images
  lazyload({
    pageUpdatedEventName: 'page:updated',
    threshold: 0.01,
    maxFrameCount: 20
  })

  document.querySelectorAll('[data-valueToggle]').forEach((toggle) => {
    const input = document.querySelector(toggle.dataset.input)
    const hiddenInput = document.querySelector(toggle.dataset.hiddenInput)
    const value = toggle.value
    toggle.addEventListener('change', () => {
      if (toggle.checked) {
        input.setAttribute('placeholder', value)
        hiddenInput.value = toggle.dataset.hiddenInputValue
      } else {
        input.setAttribute('placeholder', toggle.dataset.valuetoggle)
        hiddenInput.value = toggle.dataset.hiddenInputValuetoggle
      }
    })
  })

  window.addEventListener('resize', () => {
    iosFix()

    if (window.innerWidth !== windowWidth) {
      document.documentElement.classList.remove('page-updated')
      setTimeout(() => {
        document.documentElement.classList.add('page-updated')
      }, 2)
    }
  })

  window.addEventListener('keydown', function (e) {
    userIsTabbing(e)
  })
})

document.addEventListener('page:updated', function () {
  document.documentElement.classList.add('page-updated')
})
