export function intersections() {
  if (!A17.intersectionObserver) {
    return
  }

  const observedClass = 's-in-viewport'
  const observerSetup = {
    rootMargin: '0px',
    threshold: 0.25
  }

  function intersection(entries, observer) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i]
      if (entry.intersectionRatio > 0) {
        observer.unobserve(entry.target)
        entry.target.classList.add(observedClass)
      }
    }
  }

  let observer = new IntersectionObserver(intersection, observerSetup)

  function findAndObserve() {
    const observables = document.querySelectorAll('[data-intersection-observe]')
    observables.forEach((node) => {
      if (!node.classList.contains(observedClass)) {
        observer.observe(node)
      }
    })
  }

  document.addEventListener('page:updated', () => {
    observer.disconnect()
    observer = null
    observer = new IntersectionObserver(intersection, observerSetup)
    findAndObserve()
  })

  findAndObserve()
}

export default intersections
