export function focusTrap() {
  let element

  function focusElems() {
    if (element) {
      if (document.activeElement !== element && !element.contains(document.activeElement)) {
        setTimeout(() => {
          element.focus()
          if (element !== document.activeElement) {
            const focusable = element.querySelectorAll(
              'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            )
            focusable[0].focus()
          }
        }, 0)
      }
    } else {
      try {
        document.removeEventListener('focus', focusElems)
      } catch (err) {}
    }
  }

  function trap(event) {
    try {
      document.removeEventListener('focus', focusElems)
    } catch (err) {}

    if (!event && !event.detail.element) {
      return
    }

    element = event.detail.element
    document.addEventListener('focus', focusElems, true)
  }

  function untrap() {
    document.removeEventListener('focus', focusElems)
    element = null
  }

  document.addEventListener('focus:trap', trap, false)
  document.addEventListener('focus:untrap', untrap, false)
}

export default focusTrap
