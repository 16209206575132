export function searchClear() {
  const clearBtns = document.querySelectorAll('[data-search-clear]')
  const clearText = 'Clear'

  function reset(btn, searchInput, btnText) {
    searchInput.value = ''
    setTimeout(() => {
      searchInput.focus()
    }, 0)
    btn.innerHTML = btnText
  }

  clearBtns.forEach((btn) => {
    const btnText = btn.innerHTML
    const searchInput = btn.parentNode.querySelector('input')

    btn.innerHTML = clearText

    btn.addEventListener('click', () => {
      if (btn.innerHTML === clearText) {
        reset(btn, searchInput, btnText)
      }
    })

    searchInput.addEventListener('input', () => {
      btn.innerHTML = btnText
    })
  })
}

export default searchClear
