export function lockBody() {
  let lockBodyScrollTop = 0
  let locked = false

  function lock() {
    locked = true
    lockBodyScrollTop = 0
    lockBodyScrollTop = window.pageYOffset
    document.getElementById('a17').style.top = `${lockBodyScrollTop * -1}px`
    window.requestAnimationFrame(() => {
      document.documentElement.classList.add('s-body-locked')
    })
  }

  function unlock(event) {
    const scrollTo = event?.detail?.scrollTo ?? true
    const force = event?.detail?.force ?? false
    if (locked || force) {
      locked = false
      document.getElementById('a17').style.top = ''
      document.documentElement.classList.remove('s-body-locked')
      if (scrollTo) {
        window.scrollTo(0, lockBodyScrollTop)
        setTimeout(() => {
          window.scrollTo(0, lockBodyScrollTop)
          lockBodyScrollTop = 0
        }, 1)
      }
    }
  }

  document.addEventListener('body:lock', lock, false)
  document.addEventListener('body:unlock', unlock, false)
}

export default lockBody
