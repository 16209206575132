export function manageBehaviors(loadedBehaviorsModule, dataAttr = 'behavior', options) {
  const loadedBehaviorNames = Object.keys(loadedBehaviorsModule)
  const loadedBehaviors = {}
  const activeBehaviors = new Map()

  const lazyBehaviors = new Map()

  if (dataAttr instanceof Object) {
    options = dataAttr
    dataAttr = 'behavior'
  }
  options = {
    pageLoadedEvtName: 'page:ready',
    debug: false,
    showBehaviorNames: false,
    ...options
  }

  function loopBehaviors(node, cb) {
    if (!('querySelectorAll' in node)) {
      // Ignore text or comment nodes
      return
    }
    const behaviorNodes = [node].concat([].slice.call(node.querySelectorAll(`[data-${dataAttr}]`)))
    for (let i = 0; i < behaviorNodes.length; i++) {
      const behaviorNode = behaviorNodes[i]
      const behaviorNames =
        behaviorNode.dataset && behaviorNode.dataset[dataAttr] && behaviorNode.dataset[dataAttr].split(' ')
      if (behaviorNames) {
        behaviorNames.forEach((name) => {
          cb(name, behaviorNode)
        })
      }
    }
  }

  function destroyBehaviors(node) {
    loopBehaviors(node, (bName, bNode) => {
      const nodeBehaviors = activeBehaviors.get(bNode)
      if (!nodeBehaviors || !nodeBehaviors[bName]) {
        console.warn(`No behavior ${bName} instance on:`, bNode)
        return
      }
      nodeBehaviors[bName].destroy()
      delete nodeBehaviors[bName]
      if (Object.keys(nodeBehaviors).length === 0) {
        activeBehaviors.delete(bNode)
      }
    })
    if (window.A17) {
      window.A17.activeBehaviors = activeBehaviors
    }
    lazyBehaviors.clear()
  }

  function createBehaviors(node) {
    loopBehaviors(node, (bName, bNode) => {
      if (!loadedBehaviors[bName]) {
        console.warn(`No loaded behavior called ${bName}`)
        if (!lazyBehaviors.has(bNode)) {
          lazyBehaviors.set(bNode, [bName])
        } else if (lazyBehaviors.has(bNode)) {
          lazyBehaviors.set(bNode, [...lazyBehaviors.get(bNode), bName])
        }
        if (options.debug) {
          console.log('lazyLoaded:::-', bName)
        }
        return
      }
      if (!activeBehaviors.get(bNode)) {
        const instance = new loadedBehaviors[bName](bNode)
        instance.init()
        const nodeBehaviors = activeBehaviors.get(bNode) || {}
        nodeBehaviors[bName] = instance
        activeBehaviors.set(bNode, nodeBehaviors)
      }
    })
    if (window.A17) {
      window.A17.activeBehaviors = activeBehaviors
    }
  }

  function observeBehaviors() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.removedNodes) {
          for (let i = 0; i < mutation.removedNodes.length; i++) {
            const node = mutation.removedNodes[i]
            destroyBehaviors(node)
          }
        }
      })

      mutations.forEach((mutation) => {
        if (mutation.addedNodes) {
          for (let i = 0; i < mutation.addedNodes.length; i++) {
            const node = mutation.addedNodes[i]
            createBehaviors(node)
          }
        }
      })
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false
    })
  }

  loadedBehaviorNames.forEach((name) => {
    loadedBehaviors[name] = loadedBehaviorsModule[name]
  })

  function lazyLoadBehaviors() {
    const observers = {}
    lazyBehaviors.forEach((bNames, target) => {
      bNames.forEach((bName) => {
        observers[bName] = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting || bName === 'menu') {
              import(/* webpackPrefetch: true */ `@/js/behaviors/${bName}.js`)
                .then((module) => {
                  observer.unobserve(entry.target)
                  let instance = new module.default(entry.target)
                  instance.init()
                  const nodeBehaviors = activeBehaviors.get(entry.target) || {}
                  nodeBehaviors[bName] = instance
                  activeBehaviors.set(target, nodeBehaviors)
                })
                .catch((err) => {
                  observers[bName].unobserve(target)
                  console.log(err)
                })
            }
          })
        })
        observers[bName].observe(target)
      })
    })
  }

  document.addEventListener(options.pageLoadedEvtName, () => {
    createBehaviors(document)
    lazyLoadBehaviors()
  })

  document.addEventListener('ajax-loaded', () => {
    destroyBehaviors(document)
    createBehaviors(document)
    lazyLoadBehaviors()
  })

  createBehaviors(document)
  observeBehaviors()
  lazyLoadBehaviors()
}

export default manageBehaviors
